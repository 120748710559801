/**
 * Main App
 * All the routes are here
 */
import React, { useState, useEffect, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// import io from "socket.io-client";
// import datetimeToDate from "./helpers/datetimeToDate";

// Get logged in user provider
import { UserProvider } from "./config/UserContext";
// import config from "./config/config";
import axios from "./config/axios";

// Importing the navbar as its a common component
import Navbar from "./components/navbar";
import Loader from "./components/loader";
import { toast } from "react-toastify";
import SecondNav from "./components/secondNav";
import initSocketIo from "./helpers/initSocketIo";
import ExchangePoints from "./pages/exchangePoints";
// import reconnectWsOnError from './events/reconnectOnError';

// Importing pages
// const Home = lazy(() => import("./pages/Home"));
const HomePage = lazy(() => import("./pages/home"));
// const QuestionsByTopic = lazy(() => import("./pages/QuestionsByTopic"));
const QuestionsByTopic = lazy(() => import("./pages/topicQuestions"));
const CreateQuestion = lazy(() => import("./pages/createQuestion"));
const Notifications = lazy(() => import("./pages/notifications"));
const SingleQuestion = lazy(() => import("./pages/singleQuestion"));
const UserProfile = lazy(() => import("./pages/profile"));
const Search = lazy(() => import("./pages/search"));
const Login = lazy(() => import("./pages/auth/Login"));
const Join = lazy(() => import("./pages/auth/Join"));
const NotFound = lazy(() => import("./pages/error/NotFound"));
// const UserSettings = lazy(() => import("./pages/UserSettings"));
const VerifyAccount = lazy(() => import("./pages/verifyAccount"));
const Rules = lazy(() => import("./pages/static/Rules"));
// const PrivacyPolicy = lazy(() => import("./pages/static/PrivacyPolicy"));
const Badges = lazy(() => import("./pages/static/Badges"));
const HowItWorks = lazy(() => import("./pages/static/HowItWorks"));

function App() {
  let [loggedInUser, setloggedInUser] = useState(false);
  let [loadingUser, setLoadingUser] = useState(true);

  const wsIO = initSocketIo("");

  useEffect(() => {
    // const ev = initSocketIo('').listeners('error')
    wsIO.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });
    // console.info(ev)
    // reconnectWsOnError(() => {
    //   toast.info('Reconnecting...')
    // })
  }, []);

  // Get the logged in user
  useEffect(() => {
    (async function () {
      try {
        let { data } = await axios.get("/me");
        setloggedInUser(data);
      } catch (e) {
        setloggedInUser(false);
      }
      setLoadingUser(false);
    })();
  }, []);

  // daily points
  // useEffect(() => {
  //   (async function () {
  //     try {
  //       if (Object.keys(loggedInUser).length > 0) {
  //         const claimed_daily_points = localStorage.getItem(
  //           "claimed_daily_points"
  //         );
  //         // so we dont have to fetch everytime
  //         const right_to_claim = !claimed_daily_points
  //           ? true
  //           : datetimeToDate(claimed_daily_points) < datetimeToDate(new Date());

  //         if (right_to_claim) {
  //           // fetch if user has the daily points rights
  //           const hasDailyPoints = (
  //             await import("./api/daily-points/hasDailyPoints")
  //           ).default;

  //           let hasEarnedPoints = await hasDailyPoints();
  //           if (hasEarnedPoints) {
  //             toast("النقاط اليومية: تمت إضافة نقطة إلى حسابك", {
  //               className: "successToast",
  //             });
  //             localStorage.setItem("claimed_daily_points", new Date());
  //           }
  //         }
  //       }
  //     } catch (err) {
  //       console.log("Daily points failed, sorry !");
  //     }
  //   })();
  // }, [loggedInUser]);

  return (
    <React.Fragment>
      <UserProvider value={{ loadingUser, loggedInUser }}>
        {/* main nav bar */}
        <Router>
          <Navbar />
          <SecondNav />
          <Suspense fallback={<Loader className="mt-32 block" />}>
            <Switch>
              {/* <Route exact path="/" component={Home} /> */}

              <Route exact path="/" component={HomePage} />

              <Route
                exact
                path="/topic/:topic_name"
                component={QuestionsByTopic}
              />

              {/* for only logged in users */}
              <Route
                exact
                path="/question/create"
                component={() => {
                  if (loadingUser) {
                    return <Loader className="mt-32 mb-20" />;
                  } else {
                    if (loggedInUser) {
                      return <CreateQuestion />;
                    } else if (!loggedInUser) {
                      return <Redirect to="/login" />;
                    }
                  }
                }}
              />

              {/* Search route */}
              <Route exact path="/search/:sort/:searchFor" component={Search} />

              {/* users/consulters profile, will display the right design for the right user */}
              <Route exact path="/profile/:user_id" component={UserProfile} />

              <Route
                exact
                path="/verify/:token"
                component={(props) => {
                  if (loadingUser) {
                    return <Loader className="mt-32 mb-20" />;
                  } else {
                    if (loggedInUser) {
                      if(!loggedInUser.me.verified){
                        return <VerifyAccount props={props} />;
                      }
                      return <Redirect to="/" />;
                    } else if (!loggedInUser) {
                      return <Redirect to="/login" />;
                    }
                  }
                }}
              />

              {/* <Route
                exact
                path="/u/settings"
                component={() => {
                  if (loadingUser) {
                    return <Loader className="mt-32 mb-20" />;
                  } else {
                    if (loggedInUser) {
                      return <UserSettings />;
                    } else if (!loggedInUser) {
                      return <Redirect to="/login" />;
                    }
                  }
                }}
              /> */}

              {/* User notifications */}
              <Route
                exact
                path="/notifications"
                component={() => {
                  if (loadingUser) {
                    return <Loader className="mt-32 mb-20" />;
                  } else {
                    if (loggedInUser) {
                      return <Notifications />;
                    } else if (!loggedInUser) {
                      return <Redirect to="/login" />;
                    }
                  }
                }}
              />

              {/* Single question */}
              <Route
                exact
                path="/q/:question_title"
                component={SingleQuestion}
              />

              {/* Exchange points */}
              <Route exact path="/exchange" component={ExchangePoints} />

              {/* Auth pages */}
              <Route
                exact
                path="/login"
                component={() => {
                  if (loadingUser) {
                    return <Loader className="mt-32 mb-20" />;
                  } else {
                    if (loggedInUser) {
                      return <Redirect to="/" />;
                    } else {
                      return <Login />;
                    }
                  }
                }}
              />

              <Route
                exact
                path="/join"
                component={() => {
                  if (loadingUser) {
                    return <Loader className="mt-32 mb-20" />;
                  } else {
                    if (loggedInUser) {
                      return <Redirect to="/" />;
                    } else if (!loggedInUser) {
                      return <Join />;
                    }
                  }
                }}
              />

              {/* Static pages like about us ... */}
              {/* <Route
                exact
                path="/privacy-and-policy"
                component={PrivacyPolicy}
              /> */}
              <Route exact path="/rules" component={Rules} />
              <Route exact path="/badges" component={Badges} />
              <Route exact path="/how-it-works" component={HowItWorks} />

              {/* 404 page, this route must be the last one */}
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </Router>
      </UserProvider>
    </React.Fragment>
  );
}

export default App;

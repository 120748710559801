import React from "react";
import { NavLink } from "react-router-dom";

export default function SecondNav() {
  return (
    <div className="bg-white px-2 py-1 shadow">
      <div className="max-w-screen-xl flex items-center justify-between mx-auto flex-wrap">
        <NavLink to="/" className="flex items-center">
          {/* <span className="ml-1 align-bottom inline-block md:text-lg text-zgray">مجتمع</span> */}
          <img
            src="/images/logo-purple.png"
            alt="Zid Community"
            className="h-14 w-auto"
          />
        </NavLink>
        <div className="flex items-center text-center py-2 overflow-x-auto whitespace-nowrap">
          <a
            target="_blank"
            rel="noreferrer"
            className="mx-2 text-zgray hover:text-zpurple font-semibold"
            href="https://web.zid.sa/account/settings/productboard"
          >
            تحسينات زد
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            className="mx-2 text-zgray hover:text-zpurple font-semibold"
            href="https://blog.zid.sa"
          >
            مدونة
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            className="mx-2 text-zgray hover:text-zpurple font-semibold"
            href="https://zid.sa/meetups/"
          >
            اللقاءات
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            className="mr-2 text-zgray hover:text-zpurple font-semibold"
            href="https://zid.sa/academy/"
          >
            الأكاديمية
          </a>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import Select from "react-select";
import Loader from "../../components/loader";
import services from "../../data/services.json";
import rewards from "../../data/rewards.json";
import { useFormik } from "formik";
import exchangePoints from "../../api/points/exchangePoints";
import { toast } from "react-toastify";
import UserContext from "../../config/UserContext";
import { useContext } from "react";
import { useState } from "react";

export default function ExchangePoints() {
  const { loggedInUser, loadingUser } = useContext(UserContext);
  const [canSendRequest, setCanSetRequest] = useState(true);
  // console.log(loggedInUser, loadingUser);
  const formik = useFormik({
    initialValues: {
      service: undefined,
      reward: undefined,
    },
    onSubmit: async (val, { resetForm }) => {
      try {
        await exchangePoints(val);
        toast(
          "شكرًا ، لقد تم استلام طلبك وسنقوم بدراسته ونرد عليك في أقرب وقت ممكن",
          {
            className: "successToast",
          }
        );
        resetForm();
      } catch (err) {
        toast("هناك شئ خاطئ، يرجى المحاولة فى وقت لاحق", {
          className: "errorToast",
        });
      }
    },
  });

  useEffect(() => {
    if (loadingUser === false && typeof loggedInUser === "object") {
      if (typeof formik.values.reward !== "undefined") {
        if (
          Number(loggedInUser.me.points) <
          Number(formik.values.reward.value.points)
        ) {
          setCanSetRequest(false);
        } else {
          setCanSetRequest(true);
        }
      }
    }
  }, [loadingUser, formik.values.reward]);

  return (
    <div className="my-10 p-2">
      <div className="bg-white shadow-sm px-5 py-10 rounded-md max-w-3xl mx-auto border-t-4 border-zpurple">
        <span className="text-center block font-semibold text-xl">
          استبدل نقاطك بخدمات زد
        </span>
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-10 max-w-lg mx-auto">
            <label className="font-semibold text-gray-600 mb-2 block text-xs">
              حدد الخدمة
            </label>
            <Select
              placeholder="حدد الخدمة"
              loadingMessage={() => <Loader className="my-5" />}
              className="mb-5"
              isMulti={false}
              isSearchable
              noOptionsMessage={() => "لا يوجد خيارات"}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#8e62a71f",
                  primary: "#8e62a7",
                },
              })}
              isRtl={true}
              options={services}
              // noOptionsMessage={() => "لا مزيد من المواضيع"}
              onChange={(v) => {
                formik.setFieldValue("service", v);
                formik.setFieldValue("reward", undefined);
              }}
            />
            <div className="mt-8">
              <label className="font-semibold text-gray-600 mb-2 block text-xs">
                حدد المكافأة
              </label>
              <Select
                placeholder="حدد المكافأة"
                loadingMessage={() => <Loader className="my-5" />}
                className="w-full block"
                isMulti={false}
                noOptionsMessage={() => "لا يوجد خيارات"}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#8e62a71f",
                    primary: "#8e62a7",
                  },
                })}
                isRtl={true}
                options={
                  typeof formik.values.service === "undefined"
                    ? []
                    : rewards.filter(
                        (reward) =>
                          reward.service === formik.values.service.value
                      )
                }
                // noOptionsMessage={() => "لا مزيد من المواضيع"}
                onChange={(v) => {
                  formik.setFieldValue("reward", v);
                }}
                value={
                  typeof formik.values.reward === "undefined"
                    ? []
                    : formik.values.reward
                }
              />
            </div>

            <div className="flex items-center mt-5 py-2">
              <span className="border-dashed border-t border-gray-200 w-full block"></span>
              <span className="text-gray-300 text-center block px-2 whitespace-nowrap">
                متطلبات المكافأة
              </span>
              <span className="border-dashed border-t border-gray-200 w-full block"></span>
            </div>
            <div className="flex flex-wrap sm:flex-nowrap mt-5">
              <span className="bg-yellow-50 p-2 rounded-md block w-full text-yellow-600 text-center">
                {typeof formik.values.reward === "undefined"
                  ? " 0 "
                  : ` ${formik.values.reward.value.points} `}
                نقطة
              </span>
              {typeof formik.values.reward !== "undefined" &&
                !!formik.values.reward.value.note && (
                  <span className="bg-yellow-50 p-2 border-r border-dashed border-yellow-300 rounded-l-md block w-full text-yellow-600 text-center">
                    {formik.values.reward.value.note}
                  </span>
                )}
            </div>

            {formik.isSubmitting ||
            typeof formik.values.reward === "undefined" ||
            !canSendRequest ? (
              <div className="mt-10">
                {!canSendRequest && (
                  <p className="font-semibold bg-red-50 text-center text-red-500 p-2 mb-2 text-sm">
                    ليس لديك ما يكفي من النقاط
                  </p>
                )}
                <span className="block text-center opacity-60 w-full bg-zpurple text-white px-2 py-3 rounded-full">
                  أرسل طلبك
                </span>
              </div>
            ) : (
              <button className="mt-10 transition block w-full bg-zpurple text-white hover:opacity-90 px-2 py-3 font-bold rounded-full">
                أرسل طلبك
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
